import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 25" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M21.1276 21.5417H3.04427V3.45833H12.0859V0.875H3.04427C1.61052 0.875 0.460938 2.0375 0.460938 3.45833V21.5417C0.460938 22.9625 1.61052 24.125 3.04427 24.125H21.1276C22.5484 24.125 23.7109 22.9625 23.7109 21.5417V12.5H21.1276V21.5417ZM14.6693 0.875V3.45833H19.3064L6.60927 16.1554L8.43052 17.9767L21.1276 5.27958V9.91667H23.7109V0.875H14.6693Z" />
    </Svg>
  )
}

export default Icon
